import ReportCard from "./ReportCard";
import { SessionView } from "../../types/SessionView";
import {
	ArrowTrendingUpIcon,
	BoltIcon,
	ChatBubbleLeftIcon,
	StarIcon,
	FlagIcon,
	ClipboardDocumentCheckIcon,
	BookOpenIcon,
	ClipboardDocumentListIcon,
	LightBulbIcon,
	ListBulletIcon,
	HandThumbUpIcon,
	ChatBubbleBottomCenterTextIcon,
	SparklesIcon,
	AcademicCapIcon
} from "@heroicons/react/24/solid";
import { CheckCircleIcon as CheckCircleOutlineIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/shadcn-ui/Accordion";
import { getQuestionScoreColor } from "../../utils/getQuestionScoreColor";
import { ExperienceType } from "../../schemas/ExperienceType";
import CustomTooltip from "./CustomTooltip";
import TokenManager from "../../services/TokenManager";
import React from "react";
import { AssignedSessionService } from "../../services/AssignedSessionService";
import { useToast } from "../../hooks/useToast";
import { Loader2 } from "lucide-react";

export function normalizeCompetencyName(name: string): string {
	return name.toLowerCase().replace(/[-\s]/g, "");
}

export function getInitialComment(selectedUserSession: SessionView, title: string): string {
	return selectedUserSession.comments?.find((comment) => comment.label === title)?.content || "";
}

export function renderOverview(
	selectedUserSession: SessionView,
	userId: string,
	onAddComment?: (content: string, title: string) => void,
	hideButtons?: boolean
) {
	const title = "Overview";
	const initialComment = getInitialComment(selectedUserSession, title);
	const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();
	return (
		<ReportCard
			title={title}
			icon={<BookOpenIcon className="h-5 w-5 text-[#16013e]" />}
			onAddComment={(content, title) => {
				if (onAddComment) {
					onAddComment(content, title);
				}
			}}
			initialComment={initialComment}
			canComment={canComment}
			hideButtons={hideButtons}
		>
			{selectedUserSession.overview && <p className="text-gray-600">{selectedUserSession.overview}</p>}
		</ReportCard>
	);
}

export function renderExpectations(
	selectedUserSession: SessionView,
	userId: string,
	onAddComment?: (content: string, title: string) => void,
	hideButtons?: boolean
) {
	const title = "Expectations for this Experience";
	const initialComment = getInitialComment(selectedUserSession, title);
	const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();

	return (
		<ReportCard
			title={title}
			icon={<ClipboardDocumentCheckIcon className="h-5 w-5 text-[#16013e]" />}
			onAddComment={(content, title) => {
				if (onAddComment) {
					onAddComment(content, title);
				}
			}}
			initialComment={initialComment}
			canComment={canComment}
			hideButtons={hideButtons}
		>
			{selectedUserSession.expectations && selectedUserSession.expectations.content && (
				<p className="text-gray-600">{selectedUserSession.expectations.content}</p>
			)}
			{selectedUserSession.expectations && selectedUserSession.expectations.outlook && (
				<p className="mt-4">
					<span className="font-semibold text-gray-800 mb-2">Outlook: </span>
					<span
						className={`px-2 py-1 rounded-full text-sm font-medium ${
							selectedUserSession.expectations.outlook === "positive"
								? "bg-green-100 text-green-800"
								: selectedUserSession.expectations.outlook === "negative"
								? "bg-red-100 text-red-800"
								: "bg-yellow-100 text-yellow-600"
						}`}
					>
						{selectedUserSession.expectations.outlook.charAt(0).toUpperCase() + selectedUserSession.expectations.outlook.slice(1)}
					</span>
				</p>
			)}
		</ReportCard>
	);
}

export function renderCompetencyProgress(
	selectedUserSession: SessionView,
	userId: string,
	competencyData: { [key: string]: number[] },
	onAddComment?: (content: string, title: string) => void
) {
	if (selectedUserSession.competencies && selectedUserSession.competencies.length > 0) {
		return (
			<div className="my-12">
				{selectedUserSession.competencies.map((competency) => {
					const normalizedName = normalizeCompetencyName(competency.name);
					const competencyProgressItem = selectedUserSession.competencyProgress.find(
						(progress) => normalizeCompetencyName(progress.name) === normalizedName
					);
					const assessments = competencyData[normalizedName] || [];
					const title = competency.name.charAt(0).toUpperCase() + competency.name.slice(1);
					const initialComment = getInitialComment(selectedUserSession, `Competency Progress: ${title}`);
					const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();
					return (
						<ReportCard
							key={`competency-progress-${normalizedName}`}
							title={`Competency Progress: ${title}`}
							icon={<BoltIcon className="h-5 w-5 text-[#16013e]" />}
							reportScoreData={assessments}
							lineColor="#d4d4d8"
							currentScore={Number(competencyProgressItem?.assessment)}
							onAddComment={(content, title) => {
								if (onAddComment) {
									onAddComment(content, `Competency Progress: ${title}`);
								}
							}}
							initialComment={initialComment}
							canComment={canComment}
						>
							{competencyProgressItem && competencyProgressItem.summary && (
								<p className="text-gray-600">{competencyProgressItem.summary}</p>
							)}
						</ReportCard>
					);
				})}
			</div>
		);
	}
	return null;
}

export function renderSatisfaction(
	selectedUserSession: SessionView,
	userId: string,
	satisfactionData?: number[] | undefined,
	onAddComment?: (content: string, title: string) => void,
	hideButtons?: boolean
) {
	const title = "Satisfaction";
	const initialComment = getInitialComment(selectedUserSession, title);
	const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();

	return (
		<ReportCard
			title={title}
			icon={<StarIcon className="h-5 w-5 text-[#16013e]" />}
			reportScoreData={satisfactionData}
			lineColor="#d4d4d8"
			currentScore={selectedUserSession.satisfaction?.score}
			onAddComment={(content, title) => {
				if (onAddComment) {
					onAddComment(content, title);
				}
			}}
			initialComment={initialComment}
			canComment={canComment}
			hideButtons={hideButtons}
		>
			{selectedUserSession.satisfaction && selectedUserSession.satisfaction.reason && (
				<p className="text-gray-600 mb-4">
					<span className="font-semibold text-gray-800 mr-2">Reason for Rating:</span>
					{selectedUserSession.satisfaction.reason}
				</p>
			)}
			{selectedUserSession.satisfaction && selectedUserSession.satisfaction.best && (
				<p className="text-gray-600 mb-4">
					<span className="font-semibold text-gray-800 mr-2">Most Valuable Aspects of Course:</span>
					{selectedUserSession.satisfaction.best}
				</p>
			)}
			{selectedUserSession.satisfaction && selectedUserSession.satisfaction.worst && (
				<p className="text-gray-600 mb-4">
					<span className="font-semibold text-gray-800 mr-2">Least Valuable Aspects of Course:</span>
					{selectedUserSession.satisfaction.worst}
				</p>
			)}
		</ReportCard>
	);
}

export function renderGoalProgress(
	selectedUserSession: SessionView,
	userId: string,
	onAddComment?: (content: string, title: string) => void,
	hideButtons?: boolean
) {
	if (selectedUserSession.goalProgress && selectedUserSession.goalProgress.length > 0) {
		return (
			<>
				{selectedUserSession.goalProgress.map((goal, index) => {
					const title = `Goal Progress: ${goal.description}`;
					const initialComment = getInitialComment(selectedUserSession, title);
					const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();
					return (
						<ReportCard
							key={index}
							title={title}
							icon={<ArrowTrendingUpIcon className="h-5 w-5 text-[#16013e]" />}
							onAddComment={(content, title) => {
								if (onAddComment) {
									onAddComment(content, title);
								}
							}}
							initialComment={initialComment}
							canComment={canComment}
							hideButtons={hideButtons}
						>
							<div className="mb-4">
								{goal.goal_status && (
									<>
										<h4 className="font-semibold text-gray-800 mb-2">Goal Status</h4>
										<p
											className={`text-sm font-medium px-3 py-1 rounded-full inline-block ${
												goal.goal_status === "Yes"
													? "bg-green-100 text-green-800"
													: goal.goal_status === "Partial"
													? "bg-yellow-100 text-yellow-600"
													: "bg-red-100 text-red-800"
											}`}
										>
											{goal.goal_status}
										</p>
									</>
								)}
							</div>

							{goal.summary && <p className="text-gray-600">{goal.summary}</p>}
						</ReportCard>
					);
				})}
			</>
		);
	}

	const title = `Goal Progress:`;
	const initialComment = getInitialComment(selectedUserSession, title);
	const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();
	return (
		<ReportCard
			key={0}
			title={title}
			icon={<ArrowTrendingUpIcon className="h-5 w-5 text-[#16013e]" />}
			onAddComment={(content, title) => {
				if (onAddComment) {
					onAddComment(content, title);
				}
			}}
			initialComment={initialComment}
			canComment={canComment}
			hideButtons={hideButtons}
		>
			<p className="text-gray-600"></p>
		</ReportCard>
	);
}

export function renderGoals(
	selectedUserSession: SessionView,
	userId: string,
	onAddComment?: (content: string, title: string) => void,
	hideButtons?: boolean
) {
	const title = "Goals Before Next Call";
	const initialComment = getInitialComment(selectedUserSession, title);
	const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();

	return (
		<ReportCard
			title={title}
			icon={<FlagIcon className="h-5 w-5 text-[#16013e]" />}
			onAddComment={(content, title) => {
				if (onAddComment) {
					onAddComment(content, title);
				}
			}}
			initialComment={initialComment}
			canComment={canComment}
			hideButtons={hideButtons}
		>
			{selectedUserSession.goals && Array.isArray(selectedUserSession.goals) && selectedUserSession.goals.length > 0 && (
				<>
					{selectedUserSession.goals.map((goal, index) => (
						<div key={index} className="mb-4">
							{goal.description && (
								<p className="text-gray-800 mb-2">
									<span className="font-semibold">Goal {index + 1}:</span> {goal.description}
								</p>
							)}
						</div>
					))}
				</>
			)}
		</ReportCard>
	);
}

export function renderFinalReview(
	selectedUserSession: SessionView,
	userId: string,
	onAddComment?: (content: string, title: string) => void,
	hideButtons?: boolean
) {
	const title = "Review of Reflection Process";
	const initialComment = getInitialComment(selectedUserSession, title);
	const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();
	return (
		<ReportCard
			title={title}
			icon={<ClipboardDocumentListIcon className="h-5 w-5 text-[#16013e]" />}
			onAddComment={(content, title) => {
				if (onAddComment) {
					onAddComment(content, title);
				}
			}}
			initialComment={initialComment}
			canComment={canComment}
			hideButtons={hideButtons}
		>
			{selectedUserSession.finalReview && (
				<>
					{selectedUserSession.finalReview.takeaways && (
						<>
							<div className="mt-4">
								<h3 className="font-semibold text-gray-800 mb-2">Key Takeaways</h3>
								{Array.isArray(selectedUserSession.finalReview.takeaways) &&
									selectedUserSession.finalReview.takeaways.map((takeaway, index) => (
										<div key={index} className="mb-4 p-2 ml-2">
											{takeaway.point && <p className="text-gray-600">Point: {takeaway.point}</p>}
											{takeaway.example && <p className="text-gray-600">Example: {takeaway.example}</p>}
										</div>
									))}
							</div>
						</>
					)}
					{selectedUserSession.finalReview.growth && (
						<>
							<h3 className="font-semibold text-gray-800 mb-2">Growth</h3>
							<p className="text-gray-600 ml-4">{selectedUserSession.finalReview.growth}</p>
						</>
					)}
					{selectedUserSession.finalReview.feedback && (
						<div className="mt-4">
							<h3 className="font-semibold text-gray-800 mb-4">Feedback</h3>
							<div className="space-y-3 ml-4">
								<div>
									<span className="text-gray-700">Valuable Aspects: </span>
									{selectedUserSession.finalReview.feedback.valuableAspects && (
										<span className="text-gray-600">{selectedUserSession.finalReview.feedback.valuableAspects}</span>
									)}
								</div>
								<div>
									<span className="text-gray-700">Contribution: </span>
									{selectedUserSession.finalReview.feedback.contribution && (
										<span className="text-gray-600">{selectedUserSession.finalReview.feedback.contribution}</span>
									)}
								</div>
								<div>
									<span className="text-gray-700">Suggestions: </span>
									{selectedUserSession.finalReview.feedback.suggestions && (
										<span className="text-gray-600">{selectedUserSession.finalReview.feedback.suggestions}</span>
									)}
								</div>
								<div>
									<span className="text-gray-700">Preferred Method: </span>
									{selectedUserSession.finalReview.feedback.preferredMethod && (
										<span className="text-gray-600">
											{selectedUserSession.finalReview.feedback.preferredMethod.charAt(0).toUpperCase() +
												selectedUserSession.finalReview.feedback.preferredMethod.slice(1)}
										</span>
									)}
								</div>
								<div>
									<span className="text-gray-700">Rating: </span>
									{selectedUserSession.finalReview.feedback.rating && (
										<span className="text-gray-600">{selectedUserSession.finalReview.feedback.rating.toString()}/10</span>
									)}
								</div>
							</div>
						</div>
					)}
				</>
			)}
		</ReportCard>
	);
}

export function renderPurpose(selectedUserSession: SessionView, userId: string, onAddComment?: (content: string, title: string) => void) {
	const title = "Purpose";
	const initialComment = getInitialComment(selectedUserSession, title);
	const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();
	return (
		<ReportCard
			title={title}
			icon={<LightBulbIcon className="h-5 w-5 text-[#16013e]" />}
			onAddComment={(content, title) => {
				if (onAddComment) {
					onAddComment(content, title);
				}
			}}
			initialComment={initialComment}
			canComment={canComment}
		>
			{selectedUserSession.purpose && (
				<>
					{selectedUserSession.purpose.description && (
						<p className="text-gray-600 mb-4">
							<span className="font-semibold text-gray-800 mr-2">Description:</span>
							{selectedUserSession.purpose.description}
						</p>
					)}
					{selectedUserSession.purpose.summary && (
						<p className="mb-4">
							<span className="font-semibold text-gray-800 mr-2">Summary:</span>
							<span className="text-gray-600">{selectedUserSession.purpose.summary}</span>
						</p>
					)}
					{selectedUserSession.purpose.progress && (
						<p className="mb-4">
							<span className="font-semibold text-gray-800 mr-2">Progress:</span>
							<span
								className={`px-2 py-1 rounded-full text-sm font-medium ${
									selectedUserSession.purpose.progress === "Yes"
										? "bg-green-100 text-green-800"
										: selectedUserSession.purpose.progress === "Partial"
										? "bg-yellow-100 text-yellow-600"
										: selectedUserSession.purpose.progress === "No"
										? "bg-red-100 text-red-800"
										: "bg-blue-100 text-blue-800"
								}`}
							>
								{selectedUserSession.purpose.progress}
							</span>
						</p>
					)}
				</>
			)}
		</ReportCard>
	);
}

type Comment = {
	comment: string;
	priority: "low" | "medium" | "high";
};

export function renderNoteworthyComments(
	selectedUserSession: SessionView,
	userId: string,
	onAddComment?: (content: string, title: string) => void,
	hideButtons?: boolean
) {
	const title = "Noteworthy Comments";
	const initialComment = getInitialComment(selectedUserSession, title);
	const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();

	const renderComment = (comment: Comment | Comment[]) => {
		if (Array.isArray(comment)) {
			return comment.map((c, index) => <li key={index}>"{c.comment}"</li>);
		}
		return <li>"{comment.comment}"</li>;
	};

	return (
		<ReportCard
			title={title}
			icon={<ChatBubbleLeftIcon className="h-5 w-5 text-[#16013e]" />}
			onAddComment={(content, title) => {
				if (onAddComment) {
					onAddComment(content, title);
				}
			}}
			initialComment={initialComment}
			canComment={canComment}
			hideButtons={hideButtons}
		>
			{selectedUserSession.noteworthyComments && (
				<ul className="list-disc pl-5 space-y-1 text-gray-600 italic">
					{selectedUserSession.noteworthyComments.positive && renderComment(selectedUserSession.noteworthyComments.positive)}
					{selectedUserSession.noteworthyComments.negative && renderComment(selectedUserSession.noteworthyComments.negative)}
				</ul>
			)}
		</ReportCard>
	);
}

export function renderNoChatHistory() {
	return (
		<div className="bg-white border border-[#eaecf0] rounded-lg p-6">
			<div>
				<h2 className="text-xl font-semibold text-[#16013e]">Chat History</h2>
				<div className="mt-4 text-gray-600">
					<p>Transcript not included for this session.</p>
				</div>
			</div>
		</div>
	);
}

export function renderInterviewAssessment(
	selectedUserSession: SessionView,
	userId: string,
	onAddComment?: (content: string, title: string) => void,
	submitted?: boolean
): JSX.Element[] {
	const sections: JSX.Element[] = [];

	if (selectedUserSession.interviewAssessment) {
		// Strengths section
		sections.push(
			<div key="strengths">
				<ReportCard
					title="What Went Well"
					icon={<HandThumbUpIcon className="h-5 w-5 text-[#16013e]" />}
					onAddComment={onAddComment}
					canComment={!submitted}
				>
					<p className="text-gray-600">{selectedUserSession.interviewAssessment.strengths}</p>
				</ReportCard>
			</div>
		);

		// Improvements section
		sections.push(
			<div key="improvements">
				<ReportCard
					title="Areas to Improve"
					icon={<ArrowTrendingUpIcon className="h-5 w-5 text-[#16013e]" />}
					onAddComment={onAddComment}
					canComment={!submitted}
				>
					<p className="text-gray-600">{selectedUserSession.interviewAssessment.improvements}</p>
				</ReportCard>
			</div>
		);

		// // Outcome section
		// sections.push(
		// 	<div key="outcome">
		// 		<ReportCard
		// 			title="Interview Outcome"
		// 			icon={
		// 				selectedUserSession.interviewAssessment.outcome ? (
		// 					<CheckCircleIcon className="h-5 w-5 text-green-500" />
		// 				) : (
		// 					<XCircleIcon className="h-5 w-5 text-red-500" />
		// 				)
		// 			}
		// 			onAddComment={onAddComment}
		// 			canComment={!submitted}
		// 		>
		// 			<p className="text-gray-600 font-medium">{selectedUserSession.interviewAssessment.outcome ? "Successful" : "Unsuccessful"}</p>
		// 		</ReportCard>
		// 	</div>
		// );
	}

	return sections;
}

export function FrontlineScore({
	selectedUserSession,
	userId,
	onAddComment,
	hideButtons
}: {
	selectedUserSession: SessionView;
	userId: string;
	onAddComment?: (content: string, title: string) => void;
	hideButtons?: boolean;
}) {
	const { toast } = useToast();
	const [scores, setScores] = React.useState({
		professionalism: selectedUserSession.frontlineAssessment?.score.professionalism ?? 0,
		checklist: selectedUserSession.frontlineAssessment?.score.checklist ?? 0
	});
	const [isEdited, setIsEdited] = React.useState(false);
	const [isSaving, setIsSaving] = React.useState(false);

	if (
		selectedUserSession.experienceType !== ExperienceType.FRONTLINE ||
		!selectedUserSession.frontlineAssessment ||
		selectedUserSession.gradingType !== "numeric" ||
		!selectedUserSession.gradingWeights
	) {
		return null;
	}

	const weights = selectedUserSession.gradingWeights;
	const totalScore = scores.professionalism * (weights.professionalism / 100) + scores.checklist * (weights.checklist / 100);

	const handleScoreChange = (field: "professionalism" | "checklist", value: string) => {
		const numValue = Math.min(100, Math.max(0, Number(value)));
		setScores((prev) => ({
			...prev,
			[field]: numValue
		}));
		setIsEdited(true);
	};

	const handleSave = async () => {
		setIsSaving(true);
		try {
			await AssignedSessionService.updateFrontlineAssessmentScores(selectedUserSession.userId, selectedUserSession.scheduleId, scores);
			setIsEdited(false);
			toast({
				title: "Success",
				description: "Frontline assessment scores updated successfully.",
				variant: "default"
			});
		} catch (error) {
			console.error("Failed to update frontline assessment:", error);
			toast({
				title: "Error",
				description: "Failed to update frontline assessment. Please try again.",
				variant: "destructive"
			});
		} finally {
			setIsSaving(false);
		}
	};

	const handleCancel = () => {
		setScores({
			professionalism: selectedUserSession.frontlineAssessment.score.professionalism,
			checklist: selectedUserSession.frontlineAssessment.score.checklist
		});
		setIsEdited(false);
	};

	const title = `Overall Score: ${totalScore.toFixed(1)}%`;
	const initialComment = getInitialComment(selectedUserSession, title);
	const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();

	const hasInfoCollection = (selectedUserSession.frontlineAssessment.info_collection_evaluation || []).length > 0;

	return (
		<ReportCard
			title={title}
			icon={<AcademicCapIcon className="h-5 w-5 text-[#16013e]" />}
			onAddComment={(content, title) => {
				if (onAddComment) {
					onAddComment(content, title);
				}
			}}
			initialComment={initialComment}
			canComment={canComment}
			hideButtons={hideButtons}
		>
			<div className="space-y-4">
				<div className="space-y-2">
					<div className="flex items-center gap-2">
						<span className="text-right text-gray-600 w-32">Professionalism:</span>
						{TokenManager.hasFacultyClearance() ? (
							<input
								type="number"
								value={scores.professionalism}
								onChange={(e) => handleScoreChange("professionalism", e.target.value)}
								min="0"
								max="100"
								className="w-16 p-1 border border-gray-300 rounded"
							/>
						) : (
							<span className="text-center font-medium text-gray-800 w-16">{scores.professionalism}</span>
						)}
						<div className="flex items-center gap-1">
							<span className="text-gray-500 text-sm">({weights.professionalism}% weight)</span>
							<CustomTooltip content="Score based on professionalism level: Poor (25%), Fair (50%), Good (75%), Excellent (100%)" />
						</div>
					</div>
					<div className="flex items-center gap-2">
						<span className="text-right text-gray-600 w-32">Checklists:</span>
						{TokenManager.hasFacultyClearance() ? (
							<input
								type="number"
								value={scores.checklist}
								onChange={(e) => handleScoreChange("checklist", e.target.value)}
								min="0"
								max="100"
								className="w-16 p-1 border border-gray-300 rounded"
							/>
						) : (
							<span className="text-center font-medium text-gray-800 w-16">{scores.checklist}</span>
						)}
						<div className="flex items-center gap-1">
							<span className="text-gray-500 text-sm">({weights.checklist}% weight)</span>
							<CustomTooltip
								content={`Score based on successful completion of Communication Points${
									hasInfoCollection ? " and Information Collection" : ""
								} checklist items`}
							/>
						</div>
					</div>
				</div>
				{isEdited && TokenManager.hasFacultyClearance() && (
					<div className="flex items-center gap-2 ml-32">
						<button
							onClick={handleSave}
							className="bg-[#00a9af] text-white px-4 py-2 rounded hover:bg-[#008c91] transition-colors disabled:opacity-50"
							disabled={isSaving}
						>
							{isSaving ? (
								<>
									<Loader2 className="mr-2 h-4 w-4 animate-spin inline" />
									Saving...
								</>
							) : (
								"Save Changes"
							)}
						</button>
						<button
							onClick={handleCancel}
							className="border border-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-50 transition-colors"
						>
							Cancel
						</button>
					</div>
				)}
			</div>
		</ReportCard>
	);
}

export function renderFrontlineAssessment(
	selectedUserSession: SessionView,
	onAddComment?: (content: string, title: string) => void,
	submitted?: boolean
): JSX.Element[] {
	const sections: JSX.Element[] = [];

	if (selectedUserSession.frontlineAssessment) {
		// Professionalism section
		if (selectedUserSession.frontlineAssessment.professionalism?.status && selectedUserSession.frontlineAssessment.professionalism?.reason) {
			sections.push(
				<div key="professionalism">
					<ReportCard
						title="Professionalism"
						icon={<StarIcon className="h-5 w-5 text-[#16013e]" />}
						onAddComment={onAddComment}
						canComment={!submitted}
					>
						<div className="space-y-4">
							<div className="mb-4">
								<p className="text-gray-800 mb-2">
									<span className="font-semibold">Status: </span>
									<span
										className={`px-2 py-1 rounded-full text-sm font-medium ${
											selectedUserSession.frontlineAssessment.professionalism.status === "excellent"
												? "bg-green-100 text-green-800"
												: selectedUserSession.frontlineAssessment.professionalism.status === "good"
												? "bg-green-50 text-green-800"
												: selectedUserSession.frontlineAssessment.professionalism.status === "fair"
												? "bg-yellow-100 text-yellow-600"
												: "bg-red-100 text-red-800"
										}`}
									>
										{selectedUserSession.frontlineAssessment.professionalism.status.charAt(0).toUpperCase() +
											selectedUserSession.frontlineAssessment.professionalism.status.slice(1)}
									</span>
								</p>
								<p className="text-gray-600">
									<span className="font-semibold">Reason: </span>
									{selectedUserSession.frontlineAssessment.professionalism.reason}
								</p>
							</div>
						</div>
					</ReportCard>
				</div>
			);
		}

		// Key Points Evaluation section
		if (selectedUserSession.frontlineAssessment.key_points_evaluation?.length) {
			sections.push(
				<div key="key-points">
					<ReportCard
						title="Communication Points Checklist"
						icon={<ListBulletIcon className="h-5 w-5 text-[#16013e]" />}
						onAddComment={onAddComment}
						canComment={!submitted}
					>
						<div className="space-y-4">
							{selectedUserSession.frontlineAssessment.key_points_evaluation.map((point, index) => (
								<div key={index} className="mb-4">
									<div className="flex items-center gap-3">
										<div className="flex-shrink-0">
											{point.status ? (
												<CheckCircleIcon className="h-5 w-5 text-[#00a9af]" />
											) : (
												<CheckCircleOutlineIcon className="h-5 w-5 text-incomplete" />
											)}
										</div>
										<p className="text-gray-800">
											<span className="font-semibold">Point {index + 1}:</span> {point.point}
										</p>
									</div>
									{point.example && (
										<ul className="text-gray-600 mt-2 ml-8 italic">
											<li>• "{point.example}"</li>
										</ul>
									)}
								</div>
							))}
						</div>
					</ReportCard>
				</div>
			);
		}

		// Info Collection Evaluation section
		if (selectedUserSession.frontlineAssessment.info_collection_evaluation?.length) {
			sections.push(
				<div key="info-collection">
					<ReportCard
						title="Information Collection Checklist"
						icon={<ClipboardDocumentListIcon className="h-5 w-5 text-[#16013e]" />}
						onAddComment={onAddComment}
						canComment={!submitted}
					>
						<div className="space-y-4">
							{selectedUserSession.frontlineAssessment.info_collection_evaluation.map((point, index) => (
								<div key={index} className="mb-4">
									<div className="flex items-center gap-3">
										<div className="flex-shrink-0">
											{point.status ? (
												<CheckCircleIcon className="h-5 w-5 text-[#00a9af]" />
											) : (
												<CheckCircleOutlineIcon className="h-5 w-5 text-incomplete" />
											)}
										</div>
										<p className="text-gray-800">
											<span className="font-semibold">Point {index + 1}:</span> {point.point}
										</p>
									</div>
									{point.example && (
										<ul className="text-gray-600 mt-2 ml-8 italic">
											<li>• "{point.example}"</li>
										</ul>
									)}
								</div>
							))}
						</div>
					</ReportCard>
				</div>
			);
		}

		// Strengths section
		if (selectedUserSession.frontlineAssessment.strengths) {
			sections.push(
				<div key="strengths">
					<ReportCard
						title="What Went Well"
						icon={<HandThumbUpIcon className="h-5 w-5 text-[#16013e]" />}
						onAddComment={onAddComment}
						canComment={!submitted}
					>
						<p className="text-gray-600">{selectedUserSession.frontlineAssessment.strengths}</p>
					</ReportCard>
				</div>
			);
		}

		// Improvements section
		if (selectedUserSession.frontlineAssessment.improvements) {
			sections.push(
				<div key="improvements">
					<ReportCard
						title="Areas to Improve"
						icon={<ArrowTrendingUpIcon className="h-5 w-5 text-[#16013e]" />}
						onAddComment={onAddComment}
						canComment={!submitted}
					>
						<p className="text-gray-600">{selectedUserSession.frontlineAssessment.improvements}</p>
					</ReportCard>
				</div>
			);
		}
	}

	return sections;
}

export function renderQuestionListSummary(
	selectedUserSession: SessionView,
	userId: string,
	onAddComment?: (content: string, title: string) => void,
	submitted?: boolean
): JSX.Element[] {
	if (!selectedUserSession.questionListSummary?.length) {
		return [];
	}

	return [
		<ReportCard
			key="question-list-summary"
			title="Question List Summary"
			icon={<ChatBubbleBottomCenterTextIcon className="h-5 w-5" />}
			onAddComment={onAddComment}
			canComment={!submitted}
		>
			<Accordion type="single" collapsible className="w-full space-y-6">
				{selectedUserSession.questionListSummary.map((question, index) => {
					const averageScore = (question.assessment.score.grade_1 + question.assessment.score.grade_2) / 2;
					const colors = getQuestionScoreColor(averageScore);

					return (
						<AccordionItem
							key={index}
							value={`question-${index}`}
							className="border border-[#eaecf0] rounded-lg overflow-hidden hover:bg-[#F6F3ED] hover:border-[#00a9af] [&_[data-state=open]]:bg-[#F6F3ED]"
						>
							<AccordionTrigger className="flex w-full items-center justify-between px-4 py-3 [&>*]:no-underline hover:[&>*]:no-underline">
								<div className="flex-1 min-w-0">
									<div className="flex items-center justify-between pr-4">
										<div className="flex-1 min-w-0 pr-8">
											<span className="font-bold text-gray-900 text-left block">{question.type}</span>
											<p className="text-sm text-gray-600 mt-1 truncate text-left">{question.text}</p>
										</div>
										<div className="flex-shrink-0 w-[160px]">
											{question.type !== "Opening" && question.type !== "Closing" && (
												<div className="flex flex-col items-end">
													<span className="text-sm font-medium mb-2" style={{ color: colors.text }}>
														Score: {averageScore}
													</span>
													<div className="w-full h-2 bg-[#F6F3EC] rounded-full overflow-hidden">
														<div
															className="h-full transition-all duration-300 ease-in-out rounded-full"
															style={{
																width: `${averageScore}%`,
																backgroundColor: colors.text,
																opacity: 0.9
															}}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</AccordionTrigger>
							<AccordionContent className="p-4 bg-white">
								<div
									className={`grid ${
										question.type === "Opening" || question.type === "Closing" ? "grid-cols-1" : "grid-cols-12 gap-6"
									}`}
								>
									{/* Left Column - Question Messages */}
									<div className={`${question.type === "Opening" || question.type === "Closing" ? "" : "col-span-7"} space-y-4`}>
										<div className="space-y-3">
											{question.combined_messages?.map((message, msgIndex) => (
												<div
													key={msgIndex}
													className={`flex ${message.role === "assistant" ? "justify-start" : "justify-end"}`}
												>
													<div className="flex flex-col max-w-[80%]">
														<div
															className={`p-3 rounded-lg ${
																message.role === "assistant"
																	? "bg-[#F6F3ED] text-gray-900"
																	: "bg-[#00a9af] text-white"
															}`}
														>
															<p
																className={`text-xs font-medium mb-2 ${
																	message.role === "assistant" ? "text-gray-600" : "text-white opacity-90"
																}`}
															>
																{message.role === "assistant" ? "Interviewer" : "Interviewee"}
															</p>
															<p>{message.text}</p>
														</div>
													</div>
												</div>
											))}
										</div>
									</div>

									{/* Right Column - Feedback Section (only shown for non-Opening/Closing questions) */}
									{question.type !== "Opening" && question.type !== "Closing" && (
										<div className="col-span-5 pl-6">
											<div className="bg-[#f8f9fc] rounded-lg p-4">
												<div className="flex items-center gap-2 mb-3">
													<SparklesIcon className="h-4 w-4 text-[#303d7c]" />
													<h4 className="text-sm font-semibold text-[#303d7c]">Feedback</h4>
												</div>
												<div>
													<p className="text-[#303d7c] text-sm leading-relaxed">{question.assessment.feedback}</p>
												</div>
											</div>
										</div>
									)}
								</div>
							</AccordionContent>
						</AccordionItem>
					);
				})}
			</Accordion>
		</ReportCard>
	];
}

export function renderFrontlineScore(
	selectedUserSession: SessionView,
	userId: string,
	onAddComment?: (content: string, title: string) => void,
	hideButtons?: boolean
) {
	return <FrontlineScore selectedUserSession={selectedUserSession} userId={userId} onAddComment={onAddComment} hideButtons={hideButtons} />;
}
