import React, { useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Field, ErrorMessage } from "formik";
import { ClipboardIcon, PaperClipIcon, XCircleIcon, CheckIcon, CheckCircleIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon as CheckCircleOutlineIcon } from "@heroicons/react/24/outline";
import Papa from "papaparse";
import { Button } from "../../../shadcn-ui/Button";
import { Checkbox } from "../../../shadcn-ui/Checkbox";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../../../shadcn-ui/Select";
import { AssignmentService } from "../../../../services/AssignmentService";
import { StudentNotications } from "../../../../types/AssignmentPayload";
import CustomTooltip from "../../../Utility/CustomTooltip";
import AssignmentSettings from "./AssignmentSettings";
import TokenManager from "../../../../services/TokenManager";
import { Input } from "../../../shadcn-ui/Input";

// Define props for the AssignmentDetails component
export interface AssignmentDetailsProps {
	values: any;
	setFieldValue: (field: string, value: any) => void;
	onNext: () => void;
	onPrevious: () => void;
	isEditMode: boolean;
	assignmentType?: "reflection" | "interview" | "frontline" | "jobSearch";
	clientSettings: any;
}

// ExperienceTypeField component for reusable experience type selection
interface ExperienceTypeFieldProps {
	assignmentType: "reflection" | "jobSearch";
	values: any;
	setFieldValue: (field: string, value: any) => void;
	isAdminPath: boolean;
}

const ExperienceTypeField: React.FC<ExperienceTypeFieldProps> = ({ assignmentType, values, setFieldValue, isAdminPath }) => {
	const isReflection = assignmentType === "reflection";
	const label = isReflection ? "Experience Type *" : "Type of Job Search *";
	const placeholder = isReflection ? "Select Experience Type" : "Select Type of Job Search";
	const helpText = isAdminPath
		? `Choose the type of ${isReflection ? "experience" : "job search"} this assignment pertains to.`
		: `Choose the type of ${isReflection ? "experience" : "job search"} this reflection pertains to.`;

	const options = isReflection
		? [
				{ value: "Co-op Experience", label: "Co-op Experience" },
				{ value: "Internship", label: "Internship" },
				{ value: "Field Placement", label: "Field Placement" },
				{ value: "Capstone Project", label: "Capstone Project" },
				...(TokenManager.hasInstageAdminClearance() ? [{ value: "Course", label: "Course" }] : [])
		  ]
		: [
				{ value: "Job Search for Employment", label: "Job Search for Employment" },
				{ value: "Job Search for Co-op", label: "Job Search for Co-op" },
				{ value: "Job Search for Internship", label: "Job Search for Internship" },
				{ value: "Job Search (Other)", label: "Job Search (Other)" }
		  ];

	return (
		<div className="mt-4">
			<label htmlFor="experienceType" className="block text-sm font-medium text-gray-700 mb-1">
				{label}
			</label>
			<div className="relative">
				<Select value={values.experienceType} onValueChange={(value) => setFieldValue("experienceType", value)}>
					<SelectTrigger className="w-full px-4 py-2 text-left bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
						<SelectValue placeholder={placeholder} />
					</SelectTrigger>
					<SelectContent className="w-full bg-white border border-gray-300 rounded-md shadow-lg">
						{options.map((option) => (
							<SelectItem key={option.value} value={option.value} className="py-2 px-4 hover:bg-gray-100">
								{option.label}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
			</div>
			<p className="mt-1 text-sm text-gray-500">{helpText}</p>
		</div>
	);
};

// AssignmentDetails component for configuring assignment distribution settings
const AssignmentDetails: React.FC<AssignmentDetailsProps> = ({
	values,
	setFieldValue,
	onNext,
	onPrevious,
	isEditMode,
	assignmentType = "reflection",
	clientSettings
}) => {
	const location = useLocation();
	const isAdminPath = location.pathname.startsWith("/admin");
	const [assignmentCode, setAssignmentCode] = useState(values.assignmentCode || "");
	const [emails, setEmails] = useState<string[]>(values.recipientList ? values.recipientList.split(",").map((e: string) => e.trim()) : []);
	const [inputValue, setInputValue] = useState("");
	const [copySuccess, setCopySuccess] = useState(false);

	// Fetch new assignment code if not already set
	useEffect(() => {
		const fetchNewAssignmentCode = async () => {
			const code = await AssignmentService.getNewAssignmentCode();
			setAssignmentCode(code);
			setFieldValue("assignmentCode", code);
		};

		if (!values.assignmentCode || values.assignmentCode === "") {
			fetchNewAssignmentCode();
		}
	}, [values, setFieldValue]);

	// Set default values for notification checkboxes
	useEffect(() => {
		if (values.notifyAvailabilityWindow === undefined) {
			setFieldValue("notifyAvailabilityWindow", true);
		}
		if (values.notify24HoursBefore === undefined) {
			setFieldValue("notify24HoursBefore", false);
		}
		if (values.notifyDueDate === undefined) {
			setFieldValue("notifyDueDate", true);
		}
		if (values.gradingType === undefined) {
			setFieldValue("gradingType", "none");
		}
	}, [values, setFieldValue]);

	// Check if the form is valid
	const isFormValid = () => {
		const requiredFields = isAdminPath && assignmentType !== "frontline" ? ["title", "courseName"] : ["title"];
		const basicInfoValid = requiredFields.every((field) => values[field] && values[field].trim() !== "");
		const descriptionValid = values.description && values.description.trim() !== "";

		// Check grading weights if numeric grading is selected
		const gradingWeightsValid =
			values.gradingType !== "numeric" ||
			(values.gradingWeights && (values.gradingWeights.professionalism || 0) + (values.gradingWeights.checklist || 0) === 100);

		return basicInfoValid && descriptionValid && gradingWeightsValid;
	};

	// Copy text to clipboard
	const copyToClipboard = useCallback((text: string) => {
		navigator.clipboard.writeText(text);
		setCopySuccess(true);
		setTimeout(() => {
			setCopySuccess(false);
		}, 2000); // Reset after 2 seconds
	}, []);

	// Handle CSV file upload
	const handleFileUpload = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const file = event.target.files?.[0];
			if (file) {
				if (file.type !== "text/csv") {
					alert("Please upload a CSV file.");
					return;
				}
				Papa.parse(file, {
					complete: (results) => {
						const extractedEmails = new Set<string>();
						results.data.forEach((row: any) => {
							row.forEach((cell: any) => {
								const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
								const matches = cell.match(emailRegex);
								if (matches) {
									matches.forEach((email: string) => extractedEmails.add(email.toLowerCase()));
								}
							});
						});
						const newEmails = Array.from(extractedEmails).filter((email) => !emails.includes(email));
						if (newEmails.length > 500) {
							newEmails.length = 500; // Silently limit to 500 new emails
						}
						const updatedEmails = [...emails, ...newEmails];
						setEmails(updatedEmails);
						setFieldValue("recipientList", updatedEmails.join(","));
					},
					error: (error) => {
						console.error("Error parsing CSV:", error);
						alert("An error occurred while parsing the CSV file.");
					}
				});
			}
			// Clear the file input
			event.target.value = "";
		},
		[emails, setFieldValue, values]
	);
	// Handle input change for email field
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	// Handle key press in email input field
	const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			addEmail();
		}
	};

	// Add email to the list
	const addEmail = () => {
		const trimmedEmail = inputValue.trim();
		if (trimmedEmail && !emails.includes(trimmedEmail)) {
			const updatedEmails = [...emails, trimmedEmail];
			setEmails(updatedEmails);
			setFieldValue("recipientList", updatedEmails.join(","));
			setInputValue("");
		}
	};

	// Remove email from the list
	const removeEmail = (emailToRemove: string) => {
		const updatedEmails = emails.filter((email) => email !== emailToRemove);
		setEmails(updatedEmails);
		setFieldValue("recipientList", updatedEmails.join(","));
	};

	return (
		<div className="space-y-8">
			<Panel title="Assignment Details">
				{isAdminPath ? (
					<p className="text-base text-gray-600 mb-6">
						Give your assignment a clear name and description. Then, set how you want to distribute this assignment to students, configure
						notifications, and choose how aggregated reports are generated.
					</p>
				) : (
					<p className="text-base text-gray-600 mb-6">
						Give your practice assignment a clear name and description to help you track your progress.
					</p>
				)}
			</Panel>

			<Panel title={isAdminPath ? "1. Information" : "Information"} isValid={isFormValid()}>
				{/* Assignment Name */}
				<div>
					<label htmlFor="title" className="text-sm font-medium text-gray-700 mb-1 flex items-center">
						{isAdminPath
							? "Assignment Name *"
							: assignmentType === "interview"
							? "Interview Name *"
							: assignmentType === "frontline"
							? "Frontline Name *"
							: "Reflection Name *"}
						<CustomTooltip content="A clear, descriptive name that students and administrators will recognize." />
					</label>
					<Field
						type="text"
						name="title"
						id="title"
						className="mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 text-base p-2 text-gray-900"
						placeholder={
							assignmentType === "reflection"
								? "Reflection for AI Ethics Program"
								: assignmentType === "interview"
								? "Mock Interview"
								: assignmentType === "jobSearch"
								? "Job Search for Co-op"
								: "Frontline Training Series"
						}
					/>
					<ErrorMessage name="title" component="div" className="mt-1 text-sm text-red-600" />
				</div>

				{/* Experience Type Field */}
				{(assignmentType === "reflection" || assignmentType === "jobSearch") && (
					<ExperienceTypeField assignmentType={assignmentType} values={values} setFieldValue={setFieldValue} isAdminPath={isAdminPath} />
				)}

				{/* Description */}
				<div className="mt-4">
					<label htmlFor="description" className="text-sm font-medium text-gray-700 mb-1 flex items-center">
						Description *
						<CustomTooltip content="Explain the purpose and scope of this assignment. This context helps the AI assistant guide meaningful conversations during the reflection calls." />
					</label>
					{isAdminPath && (
						<p className="text-sm text-gray-500 mb-2">
							This description will appear in the student's initial enrolment email and on their start call page.
						</p>
					)}
					<Field
						as="textarea"
						name="description"
						id="description"
						rows={4}
						className="mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 text-base p-2 text-gray-900"
						placeholder={
							assignmentType === "reflection"
								? isAdminPath
									? "This reflection is part of our AI Ethics Program. Students will engage in 6 sessions throughout the course, each focusing on a different aspect of AI ethics. These reflections will encourage critical thinking about the ethical implications of AI in various contexts, including healthcare, finance, and social media."
									: "This reflection series will help me explore ethical considerations in AI development. Through multiple reflection sessions, I'll examine different aspects like responsible AI development, algorithmic bias, and privacy concerns. These reflections will help develop my critical thinking about AI's impact across healthcare, finance, and social media."
								: assignmentType === "interview"
								? isAdminPath
									? "This mock interview series simulates a full interview process. Students will progress through multiple interview stages. This structured approach helps students develop their interviewing skills and confidence."
									: "This mock interview series will help prepare me for real-world interviews. I will progress through multiple interview stages. These sessions will help develop my interviewing skills and confidence."
								: assignmentType === "jobSearch"
								? isAdminPath
									? "This job search assignment helps track and support students throughout their job search process. Through regular check-ins, students will discuss their progress, challenges, and next steps. These sessions ensure students stay on track with their goals and get guidance when needed."
									: "This job search assignment will help me stay accountable during my job search. Through regular check-ins, I'll discuss my progress, challenges, and next steps to ensure I'm on track with my goals."
								: "This frontline training series helps students develop customer service skills. Students will practice handling different scenarios like denying requests professionally, gathering information effectively, and providing solutions. Each stage focuses on a specific framework to build well-rounded service skills."
						}
					/>

					<ErrorMessage name="description" component="div" className="mt-1 text-sm text-red-600" />
				</div>

				{/* Course/Project Information - moved inside the Information panel */}
				{!isEditMode && isAdminPath && (
					<div className="mt-4">
						<div className="space-y-4">
							{assignmentType !== "frontline" && (
								<div>
									<label htmlFor="courseName" className="text-sm font-medium text-gray-700 mb-1 flex items-center">
										Course/Project Name *
										<CustomTooltip content="This name is used to organize course assignments at scale." />
									</label>
									<Field
										type="text"
										name="courseName"
										id="courseName"
										className="mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 text-base p-2 text-gray-900"
										placeholder={
											assignmentType === "reflection"
												? "Introduction to Artificial Intelligence"
												: assignmentType === "interview"
												? "Career Services"
												: "Hospitality"
										}
									/>
									<ErrorMessage name="courseName" component="div" className="mt-1 text-sm text-red-600" />
								</div>
							)}

							<div>
								<label htmlFor="courseCode" className="text-sm font-medium text-gray-700 mb-1 flex items-center">
									Course/Project Code
									<CustomTooltip content="The unique identifier is used to organize course assignments at scale. The code is not displayed to students." />
									<span className="text-xs text-gray-400 font-normal ml-2">(Optional)</span>
								</label>
								<Field
									type="text"
									name="courseCode"
									id="courseCode"
									className="mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 text-base p-2 text-gray-900"
								/>
								<ErrorMessage name="courseCode" component="div" className="mt-1 text-sm text-red-600" />
							</div>

							<div>
								<label htmlFor="courseSection" className="text-sm font-medium text-gray-700 mb-1 flex items-center">
									Course/Project Section
									<CustomTooltip content="The specific section of the course/project. Useful for courses with multiple sections to differentiate assignments and student groups." />
									<span className="text-xs text-gray-400 font-normal ml-2">(Optional)</span>
								</label>
								<Field
									type="text"
									name="courseSection"
									id="courseSection"
									className="mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 text-base p-2 text-gray-900"
								/>
								<ErrorMessage name="courseSection" component="div" className="mt-1 text-sm text-red-600" />
							</div>
						</div>
					</div>
				)}
			</Panel>

			{isAdminPath && (
				<>
					<Panel title="2. Grading" isValid={true}>
						<p className="text-base text-gray-600 mb-6">Select how you want this assignment to be graded.</p>
						<div className="space-y-4">
							<div className="flex items-center space-x-3">
								<Field
									type="radio"
									id="noGrading"
									name="gradingType"
									value="none"
									checked={values.gradingType === "none"}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										setFieldValue("gradingType", e.target.value);
									}}
									className="h-4 w-4 text-[#00a9af] border-gray-300 focus:ring-[#00a9af]"
								/>
								<label htmlFor="noGrading" className="text-sm text-gray-700">
									No grading
								</label>
							</div>
							<div className="flex items-center space-x-3">
								<Field
									type="radio"
									id="passFailGrading"
									name="gradingType"
									value="passOrFail"
									checked={values.gradingType === "passOrFail"}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										setFieldValue("gradingType", e.target.value);
									}}
									className="h-4 w-4 text-[#00a9af] border-gray-300 focus:ring-[#00a9af]"
								/>
								<label htmlFor="passFailGrading" className="text-sm text-gray-700 flex items-center">
									Pass or Fail
									<CustomTooltip content="Students pass if they make a genuine attempt to complete the full call." />
									<span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-[#00a9af] text-white">
										Recommended
									</span>
								</label>
							</div>
							{assignmentType === "frontline" && (
								<div className="flex items-center space-x-3">
									<Field
										type="radio"
										id="numericGrading"
										name="gradingType"
										value="numeric"
										checked={values.gradingType === "numeric"}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											setFieldValue("gradingType", e.target.value);
											// Initialize default weights if not set
											if (!values.gradingWeights) {
												setFieldValue("gradingWeights", {
													professionalism: 50,
													checklist: 50
												});
											}
										}}
										className="h-4 w-4 text-[#00a9af] border-gray-300 focus:ring-[#00a9af]"
									/>
									<label htmlFor="numericGrading" className="text-sm text-gray-700 flex items-center">
										Numeric
										<CustomTooltip content="Assigns a score out of 100 based on the weighted components you define below." />
									</label>
								</div>
							)}

							{/* Grading Weights Section */}
							{values.gradingType === "numeric" && (
								<div className="mt-6 border-t pt-4">
									<h4 className="text-sm font-medium text-gray-900 mb-4">Grading Weights</h4>
									<div className="space-y-4">
										{Object.entries(values.gradingWeights || {}).map(([category, weight]) => (
											<div key={category} className="flex items-center gap-3">
												<Input
													id={category}
													type="number"
													min="0"
													max="100"
													value={weight as number}
													onChange={(e) => {
														const newValue = e.target.value === "" ? "" : parseInt(e.target.value);
														setFieldValue("gradingWeights", {
															...values.gradingWeights,
															[category]: newValue
														});
													}}
													onBlur={(e) => {
														const value = e.target.value === "" ? 0 : parseInt(e.target.value);
														setFieldValue("gradingWeights", {
															...values.gradingWeights,
															[category]: value
														});
													}}
													className="w-24"
												/>
												<label htmlFor={category} className="text-sm text-gray-600 flex items-center">
													{category.charAt(0).toUpperCase() + category.slice(1).replace(/([A-Z])/g, " $1")} (%)
													<CustomTooltip content={getGradingWeightTooltip(category, assignmentType)} />
												</label>
											</div>
										))}

										{/* Total display */}
										<div className="pt-4">
											<div className="flex items-center mb-4">
												{(() => {
													const total = Object.values(values.gradingWeights || {})
														.map((v) => (typeof v === "number" ? v : 0))
														.reduce((sum, weight) => sum + weight, 0);

													return (
														<>
															<span
																className={`text-sm font-medium ${total === 100 ? "text-gray-700" : "text-red-600"}`}
															>
																Total: {total}%
															</span>
															{total !== 100 && <span className="ml-2 text-sm text-red-600">Must equal 100%</span>}
														</>
													);
												})()}
											</div>
										</div>
									</div>
								</div>
							)}

							{/* Grade Visibility Section */}
							{(values.gradingType === "passOrFail" || values.gradingType === "numeric") && (
								<div className="mt-6 pt-6 border-t">
									<p className="text-base font-medium text-gray-900 mb-4">Grade Visibility:</p>
									<div className="space-y-4">
										<div className="flex items-center space-x-3">
											<Field
												type="radio"
												id="hideGrades"
												name="showGradesToStudents"
												value={false}
												checked={!values.showGradesToStudents}
												onChange={() => setFieldValue("showGradesToStudents", false)}
												className="h-4 w-4 text-[#00a9af] border-gray-300 focus:ring-[#00a9af]"
											/>
											<label htmlFor="hideGrades" className="text-sm text-gray-700">
												Hide grades from students
											</label>
											<CustomTooltip content="Students will not be able to see their grades for any calls in this assignment." />
										</div>
										<div className="flex items-center space-x-3">
											<Field
												type="radio"
												id="showGrades"
												name="showGradesToStudents"
												value={true}
												checked={values.showGradesToStudents}
												onChange={() => setFieldValue("showGradesToStudents", true)}
												className="h-4 w-4 text-[#00a9af] border-gray-300 focus:ring-[#00a9af]"
											/>
											<label htmlFor="showGrades" className="text-sm text-gray-700">
												Show grades to students
											</label>
											<CustomTooltip content="Students will be able to see their grades for each call after submission." />
										</div>
									</div>
								</div>
							)}
						</div>
					</Panel>

					<Panel title="3. Distribution" isValid={true}>
						{!isEditMode ? (
							<div className="space-y-8">
								<div>
									<div className="text-base font-medium text-gray-900 mb-2 flex items-center">
										Option 1: Email Distribution
										<CustomTooltip content="Enter student emails or upload a CSV list to invite them directly." />
									</div>
									<p className="text-sm text-gray-600 mb-6">Send an email invitation to students with assignment details.</p>
									<div className="space-y-4">
										<div>
											<label htmlFor="recipientList" className="block text-sm font-medium text-gray-700 mb-2">
												Recipient List
											</label>
											<div className="flex flex-wrap gap-2 p-2 border rounded-lg focus-within:border-[#00a9af]">
												{emails.slice(0, 10).map((email, index) => (
													<div key={index} className="flex items-center bg-gray-100 rounded-full px-3 py-1">
														<span className="text-sm">{email}</span>
														<button
															type="button"
															onClick={() => removeEmail(email)}
															className="ml-2 text-gray-500 hover:text-gray-700"
														>
															<XCircleIcon className="h-4 w-4" />
														</button>
													</div>
												))}
												{emails.length > 10 && (
													<div className="flex items-center bg-gray-100 rounded-full px-3 py-1">
														<span className="text-sm">... {emails.length - 10} more</span>
													</div>
												)}
												<input
													type="text"
													value={inputValue}
													onChange={handleInputChange}
													onKeyDown={handleInputKeyDown}
													onBlur={addEmail}
													className="flex-grow outline-none"
													placeholder="Enter email addresses"
												/>
											</div>
											<ErrorMessage name="recipientList" component="div" className="mt-1 text-sm text-red-600" />
										</div>
										<div>
											<label htmlFor="csvUpload" className="block text-sm font-medium text-gray-700 mb-2">
												Or upload a CSV file with email addresses
											</label>
											<div className="mt-1 flex items-center">
												<input
													id="csvUpload"
													name="csvUpload"
													type="file"
													accept=".csv"
													onChange={handleFileUpload}
													className="sr-only"
												/>
												<label
													htmlFor="csvUpload"
													className="cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00a9af]"
												>
													<PaperClipIcon className="h-5 w-5 inline-block mr-2" />
													Upload CSV with Emails
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="border-t pt-8">
									<div className="text-base font-medium text-gray-900 mb-2 flex items-center">
										Option 2: Share Assignment Link
										<CustomTooltip content="Copy this link to distribute via LMS, Email, etc." />
									</div>
									<p className="text-sm text-gray-600 mb-6">Share this link with students (Via LMS, Email)</p>
									<div>
										<label className="block text-sm font-medium text-gray-700 mb-2">Assignment Link</label>
										<div className="flex items-center space-x-2">
											<input
												type="text"
												value={`${window.location.origin}/assignment/${assignmentCode}`}
												readOnly
												className="flex-grow px-3 py-2 h-9 bg-gray-100 rounded-md border border-gray-300 focus:outline-none"
											/>
											<Button
												variant="outline"
												onClick={() => copyToClipboard(`${window.location.origin}/assignment/${assignmentCode}`)}
												className="w-28"
											>
												{copySuccess ? (
													<>
														<CheckIcon className="h-5 w-5 mr-1" />
														Copied
													</>
												) : (
													<>
														<ClipboardIcon className="h-5 w-5 mr-1" />
														Copy
													</>
												)}
											</Button>
										</div>
									</div>
								</div>
							</div>
						) : (
							<div>
								<p className="text-sm text-gray-600 mb-6">Share this link with students (Via LMS, Email)</p>
								<div>
									<label className="block text-sm font-medium text-gray-700 mb-2">Assignment Link</label>
									<div className="flex items-center space-x-2">
										<input
											type="text"
											value={`${window.location.origin}/assignment/${assignmentCode}`}
											readOnly
											className="flex-grow px-3 py-2 h-9 bg-gray-100 rounded-md border border-gray-300 focus:outline-none"
										/>
										<Button
											variant="outline"
											onClick={() => copyToClipboard(`${window.location.origin}/assignment/${assignmentCode}`)}
											className="w-28"
										>
											{copySuccess ? (
												<>
													<CheckIcon className="h-5 w-5 mr-1" />
													Copied
												</>
											) : (
												<>
													<ClipboardIcon className="h-5 w-5 mr-1" />
													Copy
												</>
											)}
										</Button>
									</div>
								</div>
							</div>
						)}
					</Panel>

					<Panel title="4. Student Notifications" isValid={true}>
						<p className="text-base text-gray-600 mb-6">
							Choose which automated and optional notifications students receive. Notifications help keep students on track.
						</p>
						<div className="space-y-8">
							{/* Automatic Notifications */}
							<div className="mb-4">
								<p className="text-base font-medium text-gray-900 mb-2">Automatic Notifications:</p>
								<ul className="space-y-2">
									<li className="flex items-center space-x-3">
										<CheckIcon className="h-4 w-4 text-[#00a9af]" />
										<span className="text-sm text-gray-700">Enrollment notification email</span>
										<CustomTooltip
											content="This email is sent to students when they are enrolled in an assignment. It will give them a link to the assignment dashboard and include all relevant reflection call dates."
											image="https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/enrollmentEmailPNG.JPG"
										/>
									</li>
									<li className="flex items-center space-x-3">
										<CheckIcon className="h-4 w-4 text-[#00a9af]" />
										<span className="text-sm text-gray-700">Session report email</span>
										<CustomTooltip
											content="This email is sent to students after each session providing them with a pdf copy of the session report as well as a link to confirm, add comments, and submit."
											image="https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/1017.gif"
										/>
									</li>
								</ul>
							</div>

							{/* Optional Notifications */}
							<div className="mt-4 space-y-3">
								<p className="text-base font-medium text-gray-900 mb-2">Optional Notifications:</p>
								{clientSettings?.allowNotifyAvailabilityWindow && (
									<div className="flex items-center space-x-3">
										<Checkbox
											id={StudentNotications.notifyAvailabilityWindow}
											checked={values.studentNotifications?.includes(StudentNotications.notifyAvailabilityWindow)}
											onCheckedChange={(checked: boolean) => {
												const notifications = [...(values.studentNotifications || [])];
												if (checked) {
													notifications.push(StudentNotications.notifyAvailabilityWindow);
												} else {
													const index = notifications.indexOf(StudentNotications.notifyAvailabilityWindow);
													if (index > -1) notifications.splice(index, 1);
												}
												setFieldValue("studentNotifications", notifications);
											}}
										/>
										<label htmlFor={StudentNotications.notifyAvailabilityWindow} className="text-sm text-gray-700">
											Call availability window open notification
										</label>
										<CustomTooltip content="Notify students when a new stage becomes available." />
									</div>
								)}

								{clientSettings?.allowNotify24HoursBefore && (
									<div className="flex items-center space-x-3">
										<Checkbox
											id={StudentNotications.notify24HoursBefore}
											checked={values.studentNotifications?.includes(StudentNotications.notify24HoursBefore)}
											onCheckedChange={(checked: boolean) => {
												const notifications = [...(values.studentNotifications || [])];
												if (checked) {
													notifications.push(StudentNotications.notify24HoursBefore);
												} else {
													const index = notifications.indexOf(StudentNotications.notify24HoursBefore);
													if (index > -1) notifications.splice(index, 1);
												}
												setFieldValue("studentNotifications", notifications);
											}}
										/>
										<label htmlFor={StudentNotications.notify24HoursBefore} className="text-sm text-gray-700">
											24-hour reminder email
										</label>
										<CustomTooltip content="Send a reminder 24 hours before the due date." />
									</div>
								)}

								{clientSettings?.allowNotifyDueDate && (
									<div className="flex items-center space-x-3">
										<Checkbox
											id={StudentNotications.notifyDueDate}
											checked={values.studentNotifications?.includes(StudentNotications.notifyDueDate)}
											onCheckedChange={(checked: boolean) => {
												const notifications = [...(values.studentNotifications || [])];
												if (checked) {
													notifications.push(StudentNotications.notifyDueDate);
												} else {
													const index = notifications.indexOf(StudentNotications.notifyDueDate);
													if (index > -1) notifications.splice(index, 1);
												}
												setFieldValue("studentNotifications", notifications);
											}}
										/>
										<label htmlFor={StudentNotications.notifyDueDate} className="text-sm text-gray-700">
											Due date reminder email
										</label>
										<CustomTooltip content="Remind students on the due date to complete the stage if they haven't already." />
									</div>
								)}
							</div>
						</div>
					</Panel>

					<AssignmentSettings
						values={values}
						setFieldValue={setFieldValue}
						onNext={onNext}
						onPrevious={onPrevious}
						clientSettings={clientSettings}
						assignmentType={assignmentType}
					/>
				</>
			)}

			{/* Navigation buttons - visible only on larger screens */}
			<div className="hidden md:flex justify-end mt-8 gap-2">
				<Button
					type="button"
					variant="secondary"
					onClick={() => {
						onPrevious();
					}}
				>
					Previous
				</Button>
				<Button
					onClick={() => {
						onNext();
					}}
					disabled={!isFormValid()}
				>
					Next
				</Button>
			</div>
		</div>
	);
};

// Panel component for grouping related form fields
interface PanelProps {
	title: string;
	children: React.ReactNode;
	isOpen?: boolean;
	onToggle?: () => void;
	isEnabled?: boolean;
	isValid?: boolean;
}

const Panel: React.FC<PanelProps> = ({ title, children, isOpen = true, onToggle, isEnabled = true, isValid }) => (
	<div className={`bg-white border rounded-lg ${!isEnabled ? "border-gray-200" : isValid ? "border-[#00a9af]" : "border-incomplete"}`}>
		{onToggle ? (
			<button
				type="button"
				className={`w-full px-6 py-4 text-left flex justify-between items-center ${isEnabled ? "" : "opacity-50 cursor-not-allowed"}`}
				onClick={onToggle}
				disabled={!isEnabled}
			>
				<h3 className="text-lg font-semibold text-gray-800">{title}</h3>
				<div className="flex items-center space-x-2">
					{isEnabled &&
						isValid !== undefined &&
						(isValid ? (
							<CheckCircleIcon className="w-5 h-5 text-[#00a9af]" />
						) : (
							<CheckCircleOutlineIcon className="w-5 h-5 text-incomplete" />
						))}
					{isEnabled &&
						(isOpen ? <ChevronUpIcon className="w-5 h-5 text-gray-500" /> : <ChevronDownIcon className="w-5 h-5 text-gray-500" />)}
				</div>
			</button>
		) : (
			<div className="px-6 py-4 flex justify-between items-center">
				<h3 className="text-lg font-semibold text-gray-800">{title}</h3>
				{isEnabled &&
					isValid !== undefined &&
					(isValid ? (
						<CheckCircleIcon className="w-5 h-5 text-[#00a9af]" />
					) : (
						<CheckCircleOutlineIcon className="w-5 h-5 text-incomplete" />
					))}
			</div>
		)}
		{isOpen && <div className="px-6 pb-6">{children}</div>}
	</div>
);

const getGradingWeightTooltip = (category: string, assignmentType?: string) => {
	// For now, only frontline assignments have numeric grading
	const tooltips: Record<string, Record<string, string>> = {
		frontline: {
			professionalism: "Evaluates how professionally and appropriately the student handles the interaction.",
			checklist: "Score based on whether the student communicates and/or collects all the required key information points.",
			other: "Additional custom criteria that can be used for specific grading needs."
		}
	};

	return tooltips.frontline?.[category] || "Percentage weight for this grading category.";
};

export default AssignmentDetails;
