import React, { useState, useEffect } from "react";
import { ErrorMessage } from "formik";
import { CheckIcon, XCircleIcon, CheckCircleIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon as CheckCircleOutlineIcon } from "@heroicons/react/24/outline";
import CustomTooltip from "../../../Utility/CustomTooltip";
import { useAppContext } from "../../../../contexts/AppContext";
import { Checkbox } from "../../../shadcn-ui/Checkbox";

export interface AssignmentSettingsProps {
	values: any;
	setFieldValue: (field: string, value: any) => void;
	onNext: () => void;
	onPrevious: () => void;
	clientSettings: any;
	assignmentType?: "reflection" | "interview" | "frontline" | "jobSearch";
}

const AssignmentSettings: React.FC<AssignmentSettingsProps> = ({ values, setFieldValue, onNext, onPrevious, clientSettings, assignmentType }) => {
	const { instageUser } = useAppContext();
	const [additionalEmails, setAdditionalEmails] = useState<string[]>(() => {
		const initialEmails = values.additionalReportRecipients ? values.additionalReportRecipients.split(",").map((e: string) => e.trim()) : [];
		return values.reportFrequency !== "noReports" && instageUser && !initialEmails.includes(instageUser.email)
			? [...initialEmails, instageUser.email]
			: initialEmails;
	});

	const [inputValue, setInputValue] = useState("");

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			addEmail();
		}
	};

	useEffect(() => {
		if (values.includeCSV && values.includePDF) {
			setFieldValue("emailDeliveryOptions", "csv,pdf");
		} else if (values.includeCSV) {
			setFieldValue("emailDeliveryOptions", "csv");
		} else if (values.includePDF) {
			setFieldValue("emailDeliveryOptions", "pdf");
		} else {
			setFieldValue("emailDeliveryOptions", "");
		}
	}, [values.includeCSV, values.includePDF]);

	const addEmail = () => {
		const trimmedEmail = inputValue.trim();
		if (trimmedEmail && !additionalEmails.includes(trimmedEmail)) {
			const updatedEmails = [...additionalEmails, trimmedEmail];
			setAdditionalEmails(updatedEmails);
			setFieldValue("additionalReportRecipients", updatedEmails.join(","));
			setInputValue("");
		}
	};

	const removeEmail = (emailToRemove: string) => {
		const updatedEmails = additionalEmails.filter((email) => email !== emailToRemove);
		setAdditionalEmails(updatedEmails);
		setFieldValue("additionalReportRecipients", updatedEmails.join(","));
	};

	const updateEmailList = (newReportFrequency: string) => {
		let updatedEmails = [...additionalEmails];
		if (newReportFrequency !== "noReports" && instageUser && !updatedEmails.includes(instageUser.email)) {
			updatedEmails.push(instageUser.email);
		} else if (newReportFrequency === "noReports" && instageUser) {
			updatedEmails = updatedEmails.filter((email) => email !== instageUser.email);
		}
		setAdditionalEmails(updatedEmails);
		setFieldValue("additionalReportRecipients", updatedEmails.join(","));
	};

	useEffect(() => {
		updateEmailList(values.reportFrequency);
	}, [values.reportFrequency]);

	const showSummaryContent = clientSettings?.allowUserIdentification || clientSettings?.allowTranscript || clientSettings?.allowAudio;

	return (
		<div className="space-y-8">
			<Panel title="5. Aggregated Summary Options" isValid={true}>
				<p className="text-sm text-gray-600 mb-6">
					Choose what information you'd like to include in the aggregated reports sent to you 24 hours after each due date. This can help
					you track participation and performance at a glance.
				</p>

				{showSummaryContent && (
					<div className="mb-6">
						<h4 className="font-medium text-gray-800 mb-4">Summary Content</h4>
						<div className="space-y-4">
							{clientSettings?.allowUserIdentification && (
								<div className="flex items-center">
									<Checkbox
										id="includeUserIdentification"
										checked={values.includeUserIdentification}
										onCheckedChange={(checked: boolean) => {
											setFieldValue("includeUserIdentification", checked);
										}}
									/>
									<span className="ml-2 text-sm text-gray-700">Include student Name and Email</span>
									<CustomTooltip content="Identify which students completed the assignment." />
								</div>
							)}

							{assignmentType !== "interview" && clientSettings?.allowTranscript && (
								<div className="flex items-center">
									<Checkbox
										id="includeTranscript"
										checked={values.includeTranscript}
										onCheckedChange={(checked: boolean) => {
											setFieldValue("includeTranscript", checked);
										}}
									/>
									<span className="ml-2 text-sm text-gray-700">Include student transcript</span>
									<CustomTooltip content="Attach a text transcript of the student's responses." />
								</div>
							)}

							{clientSettings?.allowAudio && (
								<div className="flex items-center">
									<Checkbox
										id="includeAudio"
										checked={values.includeAudio}
										onCheckedChange={(checked: boolean) => {
											setFieldValue("includeAudio", checked);
										}}
									/>
									<span className="ml-2 text-sm text-gray-700">Include student audio recording</span>
									<CustomTooltip content="Include an audio recording of each student's session, if available." />
								</div>
							)}
						</div>
					</div>
				)}
				{clientSettings?.allowEmailReports && (
					<>
						<div className="mt-12 pt-8 border-t border-gray-200">
							<div className="flex items-center gap-2 mb-2">
								<h4 className="font-medium text-gray-800">Email Report Frequency</h4>
								<CustomTooltip content="Decide when to receive summary reports—after each due date, or only after the final due date." />
							</div>
							<div className="space-y-2">
								<label className="flex items-center">
									<input
										type="radio"
										checked={values.reportFrequency === "afterEachDueDate"}
										className="form-radio h-4 w-4"
										onChange={() => {
											setFieldValue("reportFrequency", "afterEachDueDate");
										}}
									/>
									<span className="ml-2 text-sm text-gray-700 flex items-center">
										Send a report <span className="font-bold mx-1">24 hours after each</span> due date has passed
										<CustomTooltip content="This is an aggregated summary of student activity for each assigned call generated 24 hours after its due date." />
									</span>
								</label>
								<label className="flex items-center">
									<input
										type="radio"
										checked={values.reportFrequency === "afterFinalDueDate"}
										className="form-radio h-4 w-4"
										onChange={() => {
											setFieldValue("reportFrequency", "afterFinalDueDate");
										}}
									/>
									<span className="ml-2 text-sm text-gray-700 flex items-center">
										Send a report <span className="font-bold mx-1">only after the final</span> due date has passed
										<CustomTooltip content="This is an aggregated summary of student activity for each assigned call generated 24 hours after its due date." />
									</span>
								</label>
								<label className="flex items-center">
									<input
										type="radio"
										checked={values.reportFrequency === "noReports"}
										className="form-radio h-4 w-4"
										onChange={() => {
											setFieldValue("reportFrequency", "noReports");
										}}
									/>
									<span className="ml-2 text-sm text-gray-700">Do not send email reports</span>
								</label>
							</div>
						</div>

						{values.reportFrequency !== "noReports" && (
							<>
								<div className="mt-4">
									<h4 className="font-medium text-gray-800 mb-2">What do you want included in the email?</h4>
									<div className="space-y-2">
										<label className="flex items-center">
											<CheckIcon className="h-4 w-4 text-[#00a9af]" />
											<span className="ml-2 text-sm text-gray-700">Link to admin summary</span>
										</label>
										<label className="flex items-center">
											<Checkbox
												id="includeCSV"
												checked={values.includeCSV}
												onCheckedChange={(checked: boolean) => {
													setFieldValue("includeCSV", checked);
												}}
											/>
											<span className="ml-2 text-sm text-gray-700">
												Include a CSV file of the student activity in the email
											</span>
											<CustomTooltip
												content="This includes a list of the students and their activity for each assigned call."
												image="https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/csvGif_2.gif"
											/>
										</label>
										<label className="flex items-center">
											<Checkbox
												id="includePDF"
												checked={values.includePDF}
												onCheckedChange={(checked: boolean) => {
													setFieldValue("includePDF", checked);
												}}
											/>
											<span className="ml-2 text-sm text-gray-700">Include a PDF summary in the email</span>
											<CustomTooltip
												content="This includes a PDF version of the admin summary."
												image="https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/pdfGif.gif"
											/>
										</label>
									</div>
								</div>

								<div className="mt-4">
									<div className="flex items-center gap-2 mb-2">
										<h4 className="font-medium text-gray-800">Additional Report Recipients</h4>
										<CustomTooltip content="Add colleagues who should receive the aggregated summary reports. They will have an overview of student activity without needing separate logins." />
									</div>
									<label className="block text-sm font-medium text-gray-700 mb-2">
										Do you want any other colleagues to receive copies of the activity reports?
									</label>
									<div className="flex flex-wrap gap-2 p-2 border rounded-lg focus-within:border-[#00a9af]">
										{additionalEmails.map((email, index) => (
											<div key={index} className="flex items-center bg-gray-100 rounded-full px-3 py-1">
												<span className="text-sm">{email}</span>
												<button
													type="button"
													onClick={() => removeEmail(email)}
													className="ml-2 text-gray-500 hover:text-gray-700"
												>
													<XCircleIcon className="h-4 w-4" />
												</button>
											</div>
										))}
										<input
											type="text"
											value={inputValue}
											onChange={handleInputChange}
											onKeyDown={handleInputKeyDown}
											onBlur={addEmail}
											className="flex-grow outline-none"
											placeholder="Enter email addresses"
										/>
									</div>
								</div>
							</>
						)}
						<p className="mt-2 text-sm text-gray-600 italic">
							Note: You can always log in to the dashboard to view assignment activity at any time.
						</p>
						<ErrorMessage name="reportFrequency" component="div" className="mt-2 text-sm text-red-600" />
					</>
				)}
			</Panel>
		</div>
	);
};

// Panel component for grouping related form fields
interface PanelProps {
	title: string;
	children: React.ReactNode;
	isOpen?: boolean;
	onToggle?: () => void;
	isEnabled?: boolean;
	isValid?: boolean;
}

const Panel: React.FC<PanelProps> = ({ title, children, isOpen = true, onToggle, isEnabled = true, isValid }) => (
	<div className={`bg-white border rounded-lg ${!isEnabled ? "border-gray-200" : isValid ? "border-[#00a9af]" : "border-incomplete"}`}>
		{onToggle ? (
			<button
				type="button"
				className={`w-full px-6 py-4 text-left flex justify-between items-center ${isEnabled ? "" : "opacity-50 cursor-not-allowed"}`}
				onClick={onToggle}
				disabled={!isEnabled}
			>
				<h3 className="text-lg font-semibold text-gray-800">{title}</h3>
				<div className="flex items-center space-x-2">
					{isEnabled &&
						isValid !== undefined &&
						(isValid ? (
							<CheckCircleIcon className="w-5 h-5 text-[#00a9af]" />
						) : (
							<CheckCircleOutlineIcon className="w-5 h-5 text-incomplete" />
						))}
					{isEnabled &&
						(isOpen ? <ChevronUpIcon className="w-5 h-5 text-gray-500" /> : <ChevronDownIcon className="w-5 h-5 text-gray-500" />)}
				</div>
			</button>
		) : (
			<div className="px-6 py-4 flex justify-between items-center">
				<h3 className="text-lg font-semibold text-gray-800">{title}</h3>
				{isEnabled &&
					isValid !== undefined &&
					(isValid ? (
						<CheckCircleIcon className="w-5 h-5 text-[#00a9af]" />
					) : (
						<CheckCircleOutlineIcon className="w-5 h-5 text-incomplete" />
					))}
			</div>
		)}
		{isOpen && <div className="px-6 pb-6">{children}</div>}
	</div>
);

export default AssignmentSettings;
