import React, { useEffect, useState } from "react";
import { Button } from "../../../shadcn-ui/Button";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon as CheckCircleOutlineIcon } from "@heroicons/react/24/outline";
import CustomTooltip from "../../../Utility/CustomTooltip";
import { FrontlineStage, FrontlineProgramName } from "../../../../types/FrontlinePayload";
import FrontlinePlan from "./FrontlinePlan";
import { FrontlineService } from "../../../../services/FrontlineService";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../../../shadcn-ui/Select";

const PROGRAM_OPTIONS = [
	{ label: "Flight Services", value: "Flight Services" },
	{ label: "Hospitality Services", value: "Hospitality Services" },
	{ label: "Medical Administration", value: "Medical Administration" }
] as const;

export interface FrontlineSetupProps {
	values: {
		stages: FrontlineStage[];
		frontlineProgramName?: FrontlineProgramName;
		[key: string]: any;
	};
	errors: any;
	touched: any;
	setFieldValue: (field: string, value: any) => void;
	onPrevious: () => void;
	onNext: () => void;
	isValid: boolean;
	dirty: boolean;
	isEditMode: boolean;
}

const isStageValid = (stage: FrontlineStage): boolean => {
	if (!stage.enabled) return true;
	if (!stage.frontlineStageType) return false;

	const config = stage.config;
	if (!config) return false;

	// Required fields for all stage types
	const hasRequiredFields = Boolean(
		config.frontlineStudentRole?.trim() &&
			config.frontlineAssistantRole?.trim() &&
			config.frontlineAssistantProblem?.trim() &&
			config.frontlineAssistantRequest?.trim()
	);

	if (!hasRequiredFields) return false;

	// Validate key points - required for all stages
	if (!config.frontlineKeyPoints || config.frontlineKeyPoints.length === 0 || !config.frontlineKeyPoints.every((item) => item.trim() !== "")) {
		return false;
	}

	// Additional validation based on stage type
	switch (stage.frontlineStageType) {
		case "deny":
			return Boolean(config.frontlineIssueWithRequest?.trim());
		case "comply":
		case "gather":
			return Boolean(
				config.frontlineInfoToCollect &&
					config.frontlineInfoToCollect.length > 0 &&
					config.frontlineInfoToCollect.every((item) => item.trim() !== "")
			);
		default:
			return false;
	}
};

const FrontlineSetup: React.FC<FrontlineSetupProps> = ({
	values,
	errors,
	touched,
	setFieldValue,
	onPrevious,
	onNext,
	isValid,
	dirty,
	isEditMode
}) => {
	const [savedPlans, setSavedPlans] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const fetchSavedPlans = async () => {
			if (!values.frontlineProgramName) return;

			try {
				const plans = await FrontlineService.getSavedPlans(values.frontlineProgramName);
				setSavedPlans(plans);
			} catch (err) {
				setError("Failed to load saved plans");
				console.error("Error fetching saved plans:", err);
			} finally {
				setIsLoading(false);
			}
		};

		fetchSavedPlans();
	}, [values.frontlineProgramName]);

	const handleSavedPlanSelect = (stageNumber: number, selectedPlan: any) => {
		if (!values.stages) return;

		const updatedStages = values.stages.map((stage) => {
			if (stage.frontlineStageOrderNumber === stageNumber) {
				return {
					...stage,
					frontlineStageType: selectedPlan.frontlineStageType,
					config: {
						frontlineStudentRole: selectedPlan.frontlineStudentRole,
						frontlineAssistantRole: selectedPlan.frontlineAssistantRole,
						frontlineAssistantProblem: selectedPlan.frontlineAssistantProblem,
						frontlineAssistantRequest: selectedPlan.frontlineAssistantRequest,
						frontlineKeyPoints: selectedPlan.frontlineKeyPoints || [],
						frontlineIssueWithRequest: selectedPlan.frontlineIssueWithRequest,
						frontlineInfoToCollect: selectedPlan.frontlineInfoToCollect || []
					}
				};
			}
			return stage;
		});

		setFieldValue("stages", updatedStages);
	};

	const isFormValid = () => {
		if (isEditMode) return true;

		if (!values.frontlineProgramName) return false;

		// Check if at least one stage is enabled
		const hasEnabledStage = values.stages?.some((stage) => stage.enabled);
		if (!hasEnabledStage) {
			console.log("No enabled stages");
			return false;
		}

		// Check each stage
		const isValid =
			values.stages?.every((stage) => {
				const valid = isStageValid(stage);
				return valid;
			}) ?? false;
		return isValid;
	};

	return (
		<div className="space-y-8">
			<Panel title="Configure Your Frontline Sessions">
				<p className="text-base text-gray-600 mb-4">
					Each session uses a conversation framework that guides how students should handle the interaction. Choose from three frameworks:
				</p>
				<ul className="list-disc pl-6 text-base text-gray-600 mb-4 space-y-2">
					<li>
						<span className="font-medium">Denying a Request</span>: Practice professionally saying "no" while maintaining a positive
						relationship (e.g., explaining why a request cannot be fulfilled due to policy or limitations)
					</li>
					<li>
						<span className="font-medium">Complying with a Request</span>: Assist with valid requests while ensuring all necessary
						information is collected (e.g., processing a standard service request)
					</li>
					<li>
						<span className="font-medium">Information Gathering</span>: Collect detailed information about a situation while showing
						empathy and professionalism (e.g., documenting an issue or concern)
					</li>
				</ul>
				<p className="text-base text-gray-600">
					For each session, you'll customize the scenario details, including the roles, situation, and key points students should cover.
				</p>
			</Panel>

			<Panel title="Select Program" validationState={values.frontlineProgramName ? "valid" : "invalid"}>
				<p className="text-base text-gray-600 mb-4">
					Choose the program type to customize your frontline sessions. This will determine the context and scenarios available.
				</p>
				<div>
					<label className="block text-sm font-medium text-gray-700 mb-2">Program Name*</label>
					<Select
						value={values.frontlineProgramName || ""}
						onValueChange={(value: FrontlineProgramName) => {
							setFieldValue("frontlineProgramName", value);
							setFieldValue("courseName", value);
						}}
					>
						<SelectTrigger className="w-full">
							<SelectValue placeholder="Select your program" />
						</SelectTrigger>
						<SelectContent>
							{PROGRAM_OPTIONS.map((option) => (
								<SelectItem key={option.value} value={option.value}>
									{option.label}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>
			</Panel>

			{values.frontlineProgramName &&
				values.stages
					?.filter((stage) => stage.enabled)
					.map((stage) => (
						<Panel
							key={stage.frontlineStageOrderNumber}
							title={`Session ${stage.frontlineStageOrderNumber} Setup`}
							validationState={isStageValid(stage) ? "valid" : "invalid"}
						>
							<FrontlinePlan
								frontlineStageOrderNumber={stage.frontlineStageOrderNumber}
								values={values}
								setFieldValue={setFieldValue}
								savedPlans={savedPlans}
								onSavedPlanSelect={(plan) => handleSavedPlanSelect(stage.frontlineStageOrderNumber, plan)}
							/>
						</Panel>
					))}

			<div className="hidden md:flex justify-end mt-8 gap-2">
				<Button type="button" variant="secondary" onClick={onPrevious}>
					Previous
				</Button>
				<Button onClick={onNext} disabled={!isFormValid()}>
					Next
				</Button>
			</div>
		</div>
	);
};

const Panel: React.FC<{
	title: string;
	children: React.ReactNode;
	validationState?: "valid" | "invalid" | "none";
	tooltipContent?: string;
}> = ({ title, children, validationState = "none", tooltipContent }) => (
	<div
		className={`bg-white border rounded-lg p-6 ${
			validationState === "valid" ? "border-[#00a9af]" : validationState === "invalid" ? "border-incomplete" : "border-[#eaecf0]"
		}`}
	>
		<div className="flex justify-between items-center mb-4">
			<div className="flex items-center gap-2">
				<h3 className="text-lg font-semibold text-gray-800">{title}</h3>
				{tooltipContent && <CustomTooltip content={tooltipContent} />}
			</div>
			{validationState === "valid" && <CheckCircleIcon className="w-6 h-6 text-[#00a9af]" />}
			{validationState === "invalid" && <CheckCircleOutlineIcon className="w-6 h-6 text-incomplete" />}
		</div>
		<div className="space-y-4">{children}</div>
	</div>
);

export default FrontlineSetup;
